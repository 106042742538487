<template>
  <section class="container-contact" :style="{ backgroundColor: backColor }">
    <div class="container-contact_grid">
      <div class="container-contact__content" :style="{ color: backColorTitle }">
        <h3>Want to start your next project?</h3>
        <router-link :to="{ name: 'ContactPage'}">
          <button class="contact-us-button" :style="{ color: backColorButton }" type="button">Contact Us</button>
        </router-link>
      </div>

    </div>
  </section>
</template>

<script>

export default {
  name: "ContactUs",
  props: ['color', 'colorTitle', 'colorButton'],
  data() {
    return {
      backColor: this.color,
      backColorTitle: this.colorTitle,
      backColorButton: this.colorButton,
    }
  }
}
</script>

<style scoped lang="scss">

.container-contact {
  background-color: #282828;
  width: 100%;
  position: relative;
  padding: 90px 0;
}

.container-contact_grid {
  margin: 5% auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

}

.container-contact__content {
  font-weight: 900;
  font-size: 1.8em;
  text-align: center;
  color: #fafafa;
}

.contact-us-button {
  display: inline-block;
  border-radius: 99px;
  z-index: 1;
  margin: 0 auto;
  position: absolute;
  top: 65%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fafafa;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 60px;
  letter-spacing: 2px;
}

.contact-us-button:before,
.contact-us-button:after {
  content: ' ';
  position: absolute;
  border-radius: 99px;
}

.contact-us-button:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  display: inline-block;
}

.contact-us-button:hover {
  color: rgba(45, 152, 200, 1);
  transition: all 0.5s ease;
  border: solid 1px rgba(45, 152, 200, 1);
}

.contact-us-button:after {
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background: transparent;
  overflow: hidden;
  border: none;
  border: solid 1px #fafafa;
  background: rgba(255, 255, 255, 0);
  z-index: -1;
  opacity: 1;
  transition: all 0.6s ease-in-out;
}

.contact-us-button:hover:after {
  opacity: 0;
  transform: translateY(-8px);
}

@media only screen and (max-width: 425px) {
  .contact-us-button {
    top: 75%;
    width: 17em;
  }
}

@media only screen and (max-width: 330px) {
  .contact-us-button {
    top: 85%;
    width: 14em;
  }
}
</style>