<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-careful">
        <img src="@/assets/casestudy/careful-logo.svg" alt="Careful">
        <span class="sep"></span>
        <p>Careful is the garment care specialist of choice in the GTA.
          We have earned this reputation with superior care and attention to every item that comes into our possession. We take great care of your wardrobe. A courteous and informative staff forms the
          backbone of our operation. We all strive to provide a rewarding and pleasurable experience for our customer. We have two locations as well as pickup and delivery service in Toronto</p>
      </div>
    </div>

    <div class="laptop-img careful-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/careful-main-img.png" alt="Careful">
      </div>
    </div>
    <div class="container-about careful-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/careful-diamond.svg" alt="Careful">
        <h3>About the project</h3>
        <p>When the company got purchased by our customers from WaySpa, they came to us to code the new look in order to replace the 15y old website that the previous owners
          had.<br/>Rome wasn’t built in a day, but fi we remember correctly, Careful was and it looks great for a one-pager. For a company that started almost a century ago, Careful looks young and
          fresh online!</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img class="doublefont" src="@/assets/casestudy/merriweather-font.svg" alt="Careful Main Font">
            <img src="@/assets/casestudy/merriweathers-font.svg" alt="Careful Second Font">
            <h3><span style="color:#343638">Co</span><span style="color:#EAEDF2">lo</span><span style="color:#000">rs</span></h3>

            <div class="colors">
              <div class="fcolor careful-p">Primary</div>
              <div class="scolor careful-s">Secondary</div>
            </div>

            <span class="sep vertical color-tag careful-sep"></span>
            <div class="color-codes cc-careful">
              <div class="ccodes p-careful">
                <p class="primary-c">#EAEDF2</p>
                <p class="secondary-c">#343638</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Testimonial tsTitle="#fafafa">
      <div class="testimonial-text">
        <p class="testimonial-title-text">Frederick Lemire-Collu, CTO</p>
        <p class="testimonial-text__content">"The level of expertise and professionalism that Vali and Scriptics have demonstrated throughout the multitude of projects that we've collaborated on has
          been outstanding. From design to
          development, they have proven themselves time and time again"
        </p>
      </div>
    </Testimonial>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Wayspa'}" class="left">
              <img src="@/assets/casestudy/btn-wayspa.jpg" alt="Wayspa">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Hoinarim'}" class="right">
              <img src="@/assets/casestudy/btn-hoinarim.jpg" alt="Hoinarim">
            </router-link>
          </div>
        </div>
      </div>

    </div>

    <ContactUs color="#2d2d2d"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Home/Footer";
import ContactUs from "../../components/ContactUs";
import Testimonial from "../../components/Testimonial";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Careful",
  components: {LetsChat, Testimonial, ContactUs, Footer}
}
</script>

<style lang="scss">
.logo-careful img {
  max-width: 320px;
}

.careful-laptop {
  background: #e8edf0;
}

.container-about {
  background: #f3f3f3;
}

.careful-p {
  background: #EAEDF2;
  color: #56595c;
}

.container-about h3, .container-about p {
  color: #56595c;
}

.careful-s {
  background: #343638;
}
</style>