<template>
  <div>
    <button class="button-lets-chat" v-on:click.prevent="showCalendy()">Let&#39;s chat!</button>
    <slideout-panel></slideout-panel>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "LetsChat",
  data: function () {
    return {
      isVisible: false,
      panel1Form: {
        openOn: "right"
      },
    }
  },
  methods: {
    initToTopButton: function () {
      $(document).bind('scroll', function () {
        let backToTopButton = $('.button-lets-chat');
        if ($(document).scrollTop() > 250) {
          backToTopButton.addClass('isVisible');
          this.isVisible = true;
        } else {
          backToTopButton.removeClass('isVisible');
          this.isVisible = false;
        }
      }.bind(this));
    },

    showCalendy() {

      const panel = this.$showPanel({
        component: "Panel",
        cssClass: "Panel",
        cssClass: "calendar-panel",
        props: {}
      });
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.initToTopButton();
    });
  },
}
</script>

<style lang="scss" scoped>

.button-lets-chat {
  position: fixed;
  width: 8%;
  height: 4%;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  margin: .25em;
  right: 10px;
  bottom: 10px;
  border: none;
  outline: none;
  opacity: 0;
  z-index: 9999;
  color: #fafafa;
  background-color: #2d98c8;
  font-family: "Heebo", sans-serif;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 20%;
    height: 20%;
    border: 2px solid;
    transition: all 0.6s ease;
  }

    &::after {
      bottom: 0;
      right: 0;
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: #2d98c8;
      border-right-color: #2d98c8;
    }

    &::before {
      top: 0;
      left: 0;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-top-color: #2d98c8;
      border-left-color: #2d98c8;
    }


    &:hover:after,
    &:hover:before {
      border-bottom-color:  #2d98c8;
      border-right-color:  #2d98c8;
      border-top-color: #2d98c8;
      border-left-color:  #2d98c8;
      width: 100%;
      height: 100%;

    }
  &:hover {
    opacity: 1;
    cursor: pointer;
    color: #2d98c8;
    background-color: #fafafa;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 0px 0px 34px 14px rgba(128, 128, 128, 0.76);
    box-shadow: 0px 10px 13px -7px #000000, 0px 0px 34px 14px rgba(128, 128, 128, 0.76);
  }
}

.isVisible {
  opacity: .8;
  transition: all .4s ease-in;
}

.disabled {
  pointer-events: none;
}

@media only screen and (max-width: 1024px) {
  .button-lets-chat {
    width: 12%;
  }
}

@media only screen and (max-width: 500px) {
 .button-lets-chat {
   height: fit-content;
   width: 23%;
   right: 0;
 }
}
@media only screen and (max-width: 330px) {
  .button-lets-chat {
    width: 19%;
  }
}
</style>
