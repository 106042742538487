<template>
  <div class="container-testimonial" :style="{ backgroundColor: testimonialBackground }">
    <div class="container-testimonial__content">
      <h3 class="testimonial-title" :style="{ color: testimonialTitle }">Testimonial</h3>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Testimonial",
  props: ['backgroundTsColor', 'tsTitle'],
  data() {
    return {
      testimonialTitle: this.tsTitle,
      testimonialBackground: this.backgroundTsColor
    }
  }
}
</script>

<style scoped lang="scss">

.container-testimonial {
  background-color: #2d2d2d;
  position: relative;
  clear: both;
  display: block;
  padding: 50px 0 30px;
  overflow: hidden;
}


.testimonial-title {
  font-size: 50px;
  padding-bottom: 48px;
  width: 100%;
  color: #fafafa;
  text-align: center;
  font-weight: 200;
}

.container-testimonial__content {
  background: url(../assets/casestudy/quote.svg) top left no-repeat;
  background-position-x: 15%;
  background-size: 10%;
}

.testimonial-title:before {
  position: absolute;
  width: 135px;
  height: 106px;
  display: block;
  content: " ";
  font-size: 0;
  left: 25px;
  top: 11px;
  background: url(../assets/deco-cs.png) center/contain no-repeat;
}

@media only screen and (max-width: 425px) {
  .testimonial-title:before {
    display: none;
  }

  .container-testimonial__content {
    background-position-x: 3%;
    background-size: 14%;
  }
}
</style>